import React from 'react';

import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Snackbar from '@mui/material/Snackbar';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';

class ContractSummary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { copiedToast: false, toastMessage: "" };
        this.handleContractToClick = this.handleContractToClick.bind(this);
        this.handleDestinationClick = this.handleDestinationClick.bind(this);
        this.handleCloseToast = this.handleCloseToast.bind(this);
        this.handleVolumeClick = this.handleVolumeClick.bind(this);
        this.handleCollateralClick = this.handleCollateralClick.bind(this);
        this.handleRewardClick = this.handleRewardClick.bind(this);
    }
    // Focuses entry when field is selected
    handleFocus(e) {
        e.target.select();
    }

    handleContractToClick() {
        navigator.clipboard.writeText("Coffee Republic");
        this.setState({ copiedToast: true, toastMessage: "Coffee Repubilc" });
    }

    handleDestinationClick() {
        navigator.clipboard.writeText(this.props.contractDestination);
        this.setState({ copiedToast: true, toastMessage: "Destination" });
    }

    handleVolumeClick() {
        navigator.clipboard.writeText(this.props.totalVolume);
        this.setState({ copiedToast: true, toastMessage: "Volume" });
    }

    handleCollateralClick () {
        navigator.clipboard.writeText(this.props.totalCollateral);
        this.setState({ copiedToast: true, toastMessage: "Collateral" });
    }

    handleRewardClick() {
        navigator.clipboard.writeText(this.props.calculatedReward);
        this.setState({ copiedToast: true, toastMessage: "Reward" });
    }

    handleCloseToast(e, reason) {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ copiedToast: false });
    }

    renderVolumeFormatted() {
        return this.props.totalVolume.toLocaleString();
    }

    renderCollateralFormatted() {
        return this.props.totalCollateral.toLocaleString();
    }

    // If there are any over capacity errors in the appraisal or manual
    // entries, the calculated reward is hidden to discourage the user
    // from submitting improper contracts
    renderRewardFormatted() {
        if(this.props.isAppraisalCollateralOver || this.props.isAppraisalVolumeOver || this.props.isVolumeOver || this.props.isCollateralOver) {
            return "Error";
        } else {
            return this.props.calculatedReward.toLocaleString();
        }
    }

    render() {
        return (
        <Box sx={{
            ".MuiInputBase-root": {
                fontSize: 14,
                fontWeight: "bold",
            },
            ".MuiOutlinedInput-notchedOutline": {
                borderColor: "secondary.main",
            },
            ".MuiInputLabel-root": {
                color: "secondary.main",
                bgcolor: "primary.dark",
                pr: 1,
            },
            ".MuiInputLabel-root.Mui-focused": {
                fontWeight: "bold",
            },
            ".MuiIconButton-root:hover": {
                color: "secondary.main",
                bgColor: "transparent",
            }
        }}>
            <Snackbar 
                open={this.state.copiedToast}
                autoHideDuration={1200}
                anchorOrigin={{horizontal: "center", vertical: "top"}}
                onClose={this.handleCloseToast}>
                <Alert severity="warning" icon={<TaskAltIcon />} elevation={8}>
                    {this.state.toastMessage} Copied!
                </Alert>
            </Snackbar>
            <FormControl sx={{ m: 2, display: 'flex' }}>
                <TextField
                    label="Contract To"
                    value="Coffee Republic"
                    color="secondary"
                    size="small"
                    onFocus={this.handleFocus}
                    InputProps={{
                        endAdornment:
                            <InputAdornment position="end">
                                <Tooltip
                                    title="Copy"
                                    placement="right"
                                    arrow
                                    PopperProps={{ 
                                                sx: { ".MuiTooltip-tooltip":
                                                    {
                                                        color: "black",
                                                        background: "white",
                                                        fontWeight: "bold",
                                                        fontSize: 12 
                                                    },
                                                        ".MuiTooltip-arrow": { color: "white" }}
                                                }}>
                                    <IconButton onClick={this.handleContractToClick}><ContentPasteIcon /></IconButton>
                                </Tooltip>
                            </InputAdornment>
                        }}
                    helperText=" ">
                </TextField>
                <TextField
                    label="Ship To"
                    value={this.props.contractDestination}
                    color="secondary"
                    size="small"
                    onFocus={this.handleFocus}
                    InputProps={{
                        endAdornment:
                            <InputAdornment position="end">
                                <Tooltip
                                    title="Copy"
                                    placement="right"
                                    arrow
                                    PopperProps={{ 
                                                sx: { ".MuiTooltip-tooltip":
                                                    {
                                                        color: "black",
                                                        background: "white",
                                                        fontWeight: "bold",
                                                        fontSize: 12 
                                                    },
                                                        ".MuiTooltip-arrow": { color: "white" }}
                                                }}>
                                    <IconButton onClick={this.handleDestinationClick}><ContentPasteIcon /></IconButton>
                                </Tooltip>
                            </InputAdornment>
                        }}
                    helperText=" ">
                </TextField>
                <TextField
                    label="Total Volume"
                    value={this.renderVolumeFormatted()}
                    color="secondary"
                    size="small"
                    onFocus={this.handleFocus}
                    InputProps={{
                        readOnly: true,
                        endAdornment:
                            <InputAdornment position="end">
                                m&sup3;
                                <Tooltip
                                    title="Copy"
                                    placement="right"
                                    arrow
                                    PopperProps={{ 
                                                sx: { ".MuiTooltip-tooltip":
                                                    {
                                                        color: "black",
                                                        background: "white",
                                                        fontWeight: "bold",
                                                        fontSize: 12 
                                                    },
                                                        ".MuiTooltip-arrow": { color: "white" }}
                                                }}>
                                    <IconButton onClick={this.handleVolumeClick}><ContentPasteIcon /></IconButton>
                                </Tooltip>
                            </InputAdornment>
                        }}
                    helperText=" ">
                </TextField>
                <TextField
                    label="Total Collateral"
                    value={this.renderCollateralFormatted()}
                    color="secondary"
                    size="small"
                    onFocus={this.handleFocus}
                    InputProps={{
                        readOnly: true,
                        endAdornment:
                            <InputAdornment position="end">
                                ISK
                                <Tooltip
                                    title="Copy"
                                    placement="right"
                                    arrow
                                    PopperProps={{ 
                                                sx: { ".MuiTooltip-tooltip":
                                                    {
                                                        color: "black",
                                                        background: "white",
                                                        fontWeight: "bold",
                                                        fontSize: 12 
                                                    },
                                                        ".MuiTooltip-arrow": { color: "white" }}
                                                }}>
                                    <IconButton onClick={this.handleCollateralClick}><ContentPasteIcon /></IconButton>
                                </Tooltip>
                            </InputAdornment>
                        }}
                    helperText=" ">
                </TextField>
                <TextField
                    label="Reward"
                    value={this.renderRewardFormatted()}
                    color="secondary"
                    size="small"
                    onFocus={this.handleFocus}
                    InputProps={{
                        readOnly: true,
                        endAdornment:
                            <InputAdornment position="end">
                                ISK
                                <Tooltip
                                    title="Copy"
                                    placement="right"
                                    arrow
                                    PopperProps={{ 
                                                sx: { ".MuiTooltip-tooltip":
                                                    {
                                                        color: "black",
                                                        background: "white",
                                                        fontWeight: "bold",
                                                        fontSize: 12 
                                                    },
                                                        ".MuiTooltip-arrow": { color: "white" }}
                                                }}>
                                    <IconButton onClick={this.handleRewardClick}><ContentPasteIcon /></IconButton>
                                </Tooltip>
                            </InputAdornment>
                        }}
                    helperText=" ">
                </TextField>
                <Alert
                    severity="warning"
                    icon={<InfoOutlinedIcon sx={{ color: "#0f0f0f" }}/>}
                    sx={{
                        m: 0,
                        background: "#c67100",
                        fontSize: "12px",
                        color: "#0f0f0f"
                        }}>
                    Contract privately to the corporation <strong>Coffee Republic</strong> [CAFE-] with an expiration of 7 days and 3 days to complete.
                </Alert>
            </FormControl>
        </Box>
    )}
}

export default ContractSummary;