import React from 'react';

import Alert from '@mui/material/Alert';
import FormControl from '@mui/material/FormControl';

class AppraisalErrors extends React.Component {
    renderErrorField() {
        if (this.props.appraisalError) {
            return (
                <Alert sx={{ fontSize: "12px", mb: 2 }} severity="error">
                    The following lines could be appraised.
                    <ul>
                        {this.formatErrors()}
                    </ul>
                </Alert>
                );
        } return;
    }

    // Janice API returns a string separated by \n of lines it could
    // not appraise, this function converts them into lines for
    // the user to examine
    formatErrors() {
        return this.props.appraisalError.split("\n").map(error => {
            return <li>{error}</li>;
        })
    }
    
    render() {
        return (
            <FormControl>
            {this.renderErrorField()}
            </FormControl>
        )
    }
}

export default AppraisalErrors;