import React from 'react';

import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import Typography from '@mui/material/Typography';

const percentOptions = [
    {
        value: 0,
        label: "0%"
    },
    {
        value: 1,
        label: "1%"
    },
    {
        value: 3,
        label: "3%"
    },
    {
        value: 5,
        label: "5%"
    },
    {
        value: 10,
        label: "10%"
    },
    {
        value: 15,
        label: "15%"
    },
    {
        value: 20,
        label: "20%"
    }
]

class AppraisalPercentCollateral extends React.Component {
    constructor(props) {
        super(props);
        this.handlePercentCollateralChange = this.handlePercentCollateralChange.bind(this);
    }
    
    handlePercentCollateralChange(e) {
        this.props.onPercentCollateralChange(e.target.value);
    }

    // Adjusts max percent additional collateral if setting it to anything 20% or below will
    // cause the total collateral to exceed the maximum.
    handleMaxPercentCollateral() {
        if (this.props.appraisalCollateral !== 0) {
            return Math.min(20, (50000000000 - this.props.appraisalCollateral) / 50000000000 * 100)
        } return 20;
    }

    // Tested on appraisal collateral so the user cannot trigger a
    // calculation on something of zero value
    disablePercentCollateralSlider() {
        if (this.props.appraisalCollateral === 0) {
            return true;
        } return false;
    }

    render() {
        return (
            <Box sx={{
                    mx: 1,
                    mb: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    ".MuiSlider-markLabel": {
                        fontSize: 12     
                    },
                    ".MuiSlider-thumb": {
                        height: "18px",
                        width: "18px",
                        backgroundColor: "white",
                        border: "2px solid",
                        borderColor: "primary.main",
                        boxShadow: 'none',
                    },
                    ".MuiSlider-rail": {
                        height: "5px"
                    },
                    ".MuiSlider-track": {
                        height: "5px"
                    },
                    ".MuiSlider-valueLabel": {
                        color: "secondary.main",
                        fontWeight: "bold",
                        backgroundColor: "primary.dark",
                    }
                }}>
  
                <Typography fontSize={12} color="grey">
                    Additional Percent Collateral on Appraisal
                </Typography>

                    <Slider
                        defaultValue={0}
                        size="small"
                        max={this.handleMaxPercentCollateral()}
                        step={null}
                        marks={percentOptions}
                        onChange={this.handlePercentCollateralChange}
                        value={this.props.percentCollateral}
                        disabled={this.disablePercentCollateralSlider()}
                        valueLabelDisplay="auto"
                        >
                    </Slider>

            </Box>
        );
    }
}

export default AppraisalPercentCollateral;