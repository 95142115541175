import React from 'react';

import Contract01 from './contract01.png';
import Contract02 from './contract02.png';
import Contract03 from './contract03.png';
import Contract04 from './contract04.png';
import Contract05 from './contract05.png';
import Contract06 from './contract06.png';
import Contract07 from './contract07.png';
import Contract08 from './contract08.png';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

class Instructions extends React.Component {
    
    render() {
        return (
            <Box sx={{ color: "secondary.main", ".MuiTypography-root": { fontSize: 14 }}}>
                <Typography sx={{ fontWeight: "bold" }}>
                    1. Highlight items to be contracted.
                </Typography>
                <Typography>
                    Select all of the items that you would like to contract to move and hit Ctrl + C copy them.
                    <br /><br />
                    <Box sx={{ display: "flex", justifyContent: "center" }}><img alt="Contract01" src={Contract01} width="300px" /></Box>
                </Typography>
                <br />
                <Typography sx={{ fontWeight: "bold" }}>
                    2. Paste items into the calculator.
                </Typography>
                <Typography>
                    In the appraisal field of the Dark Horse calculator, paste the earlier copied items with Ctrl + V and click the "Appraise" button on the calculator.
                    <br /><br />
                    <Box sx={{ display: "flex", justifyContent: "center" }}><img alt="Contract02" src={Contract02} width="350px" /></Box>
                </Typography>
                <br />
                <Typography sx={{ fontWeight: "bold" }}>
                    3. Contract the highlighted items.
                </Typography>
                <Typography>
                    In your EVE client, right-click the highlighted items and select "Create Contract".
                    <br /><br />
                    <Box sx={{ display: "flex", justifyContent: "center" }}><img alt="Contract03" src={Contract03} width="150px" /></Box>
                </Typography>
                <br />
                <Typography sx={{ fontWeight: "bold" }}>
                    4. Set the contract to Courier privately to Coffee Republic.
                </Typography>
                <Typography>
                    On the first contract screen, set the contract type to "Courier", the availability to "Private" and enter "Coffee Republic" in the text field. Once done, click "Next" at the bottom on the window.
                    <br /><br />
                    <Box sx={{ display: "flex", justifyContent: "center" }}><img alt="Contract04" src={Contract04} width="350px" /></Box>
                </Typography>
                <br />
                <Typography sx={{ fontWeight: "bold" }}>
                    5. Confirm that checked items are correct.
                </Typography>
                <Typography>
                    On the second contract screen, confirm that the check items to be contracted are correct. If you make changes here, you will need to redo the appraisal in the Dark Horse calculator. Once done, click "Next" at the bottom of the window.
                    <br /><br />
                    <Box sx={{ display: "flex", justifyContent: "center" }}><img alt="Contract05" src={Contract05} width="350px" /></Box>
                </Typography>
                <br />
                <Typography sx={{ fontWeight: "bold" }}>
                    6. Set the destination for the contract.
                </Typography>
                <Typography>
                    On the third contract screen, enter the destination structure/station for your contract. Once done, click "Next" at the bottom of the window.
                    <br /><br />
                    If you are using a neutral trading toon, you probably will not be able to set a Horde structure as the contract's destination because neutrals cannot see Horde structures.
                    <br /><br />
                    You will need to send an in-game link of the Horde structure to your neutral alt via in-game mail. Using your nuetral, drag that link into the destination field.
                    <br /><br />
                    <Box sx={{ display: "flex", justifyContent: "center" }}><img alt="Contract06" src={Contract06} width="350px" /></Box>
                </Typography>
                <br />
                <Typography sx={{ fontWeight: "bold" }}>
                    7. Copy the collateral and reward from the calculator.
                </Typography>
                <Typography>
                    On the fourth contract screen, copy in the Total Collateral from the Dark Horse calculator into the Collateral number field. Similarly copy the Reward from the Dark Horse calculator into the Reward number field. Once done, click "Next" at the bottom of the window.
                    <br /><br />
                    There are copy icons for easy coping on the right side of each field in the Dark Horse calculator.
                    <br /><br />
                    <Box sx={{ display: "flex", justifyContent: "center" }}><img alt="Contract07" src={Contract07} width="350px" /></Box>
                </Typography>
                <br />
                <Typography sx={{ fontWeight: "bold" }}>
                    8. Confirm and complete the contract.
                </Typography>
                <Typography>
                    On the last contract screen, confirm everything is correct and click "Finish" to complete the contract.
                    <br /><br />
                    <Box sx={{ display: "flex", justifyContent: "center" }}><img alt="Contract08" src={Contract08} width="350px" /></Box>
                </Typography>
            </Box>
        );
    }
}

export default Instructions;