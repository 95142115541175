import React from 'react';
import './Footer.css';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2';

import discord from './discordWhite.png';
import horde from './horde.png';
import eve from './ccpeveWhite.png';
import janice from './janice.png';

class Footer extends React.Component {
    handleDiscord() {
        window.open("https://discord.gg/tpQe3yevdz");
    }

    handleCcp() {
        window.open("https://www.eveonline.com/");
    }

    handleJanice() {
        window.open("https://janice.e-351.com/");
    }

    handleHorde() {
        window.open("https://www.pandemic-horde.org/");
    }
    
    render() {
        return (
            <Box
                color="white"
                bgcolor="#0f0f0f"
                px={{ xs: 3, sm: 7 }}
                py={{ xs: 5, sm: 7 }}
                mt={{ xs: 10, xl: 35 }}
                >
                <Container maxWidth="xl">
                
                <Grid container spacing={5}>
                    <Grid item xs={12} sm={6} md={3}>
                        <Box borderBottom={1} />
                            <Box sx={{ pt: 3, pl: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', maxWidth: "230px"}}>
                            <Button><img alt="discord" src={discord} height="40px" onClick={this.handleDiscord} /></Button>
                            <h6>Join the Dark Horse Discord for notifications about upcoming runs.</h6>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Box borderBottom={1} />
                            <Box sx={{ pt: 3, pl: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', maxWidth: "200px"}}>
                            <Button><img alt="ccp" src={eve} height="40px" onClick={this.handleCcp} /></Button>
                            <h6>All Eve Related Materials are property of CCP Games.</h6>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Box borderBottom={1} />
                            <Box sx={{ pt: 3, pl: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', maxWidth: "200px"}}>
                            <Button><img alt="janice" src={janice} height="40px" onClick={this.handleJanice}/></Button>
                            <h6>Appraisal API and functionality provided by Janice.</h6>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Box borderBottom={1} />
                            <Box sx={{ pt: 3, pl: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', maxWidth: "200px"}}>
                            <Button><img alt="horde" src={horde} height="40px" onClick={this.handleHorde}/></Button>
                            <h6>Join Pandemic Horde!</h6>
                        </Box>
                    </Grid>
                </Grid>
                </Container>
            </Box>
        )
    }
}

export default Footer