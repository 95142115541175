const janiceKey = process.env.REACT_APP_JANICE_KEY;
const baseEndpoint = 'https://janice.e-351.com';
const appraisalEndpoint = '/api/rest/v2/appraisal?market=2&persist=true&compactize=true&pricePercentage=1';
const url = `${baseEndpoint}${appraisalEndpoint}`;


const appraisal = async (rawPayload) => {
    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'accept': 'application/json',
                'x-ApiKey': janiceKey,
                'Content-Type': 'text/plain',
            },
            body: rawPayload
        })
        const data = await response.json();
        return {
            collateral: data.effectivePrices.totalSellPrice,
            volume: data.totalPackagedVolume,
            janiceUrlCode: data.code,
            appraisalErrors: data.failures
        }
        } catch(error) {
            console.log(error);
        }
}

export default appraisal