import React from 'react';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import { IconButton, MenuItem } from '@mui/material';

const pages = ["Instructions","FAQ", "Change Log"];

class MenuBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            smallMenuAnchor: null,
            smallMenu: false,
        };
    this.handleSmallMenuClick = this.handleSmallMenuClick.bind(this);
    this.handleCloseSmallMenu = this.handleCloseSmallMenu.bind(this);
    this.handleNavButtonClick = this.handleNavButtonClick.bind(this);
    }

    // In small viewport the navigation bar is reduced to a hamburger menu
    handleSmallMenuClick(e) {
        this.setState({ smallMenu: true, smallMenuAnchor: e.target });
    }

    handleCloseSmallMenu() {
        this.setState({ smallMenu: false, smallMenuAnchor: null });
    }

    // This function sends back which page has been clicked
    handleNavButtonClick(e) {
        this.props.dialogWindow(e.target.value);
    }

    handleDiscord() {
        window.open("https://discord.gg/tpQe3yevdz");
    }

    render() {
        return (
            <AppBar position="static" sx={{ background: "#0f0f0f" }}>
                <Toolbar disableGutters sx={{ px: 3, minHeight: "40px !important" }}>
                    <Box sx={{ flexGrow: 1, display: { xs: "flex", sm: "none" } }}>
                        <IconButton onClick={this.handleSmallMenuClick} sx={{ color: "white", ":hover": { color: "primary.main" } }}>
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="nav-menu"
                            keepMounted
                            anchorEl={this.state.smallMenuAnchor}
                            open={this.state.smallMenu}
                            onClose={this.handleCloseSmallMenu}
                            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                            transformOrigin={{ vertical: "top", horizontal: "left" }}>
                                {pages.map(page => (
                                    <MenuItem
                                        key={page}
                                        onClick={this.handleCloseSmallMenu}>
                                        <Button
                                            value={page}
                                            onClick={this.handleNavButtonClick}
                                            sx={{
                                                color: "secondary.main",
                                                background: "transparent",
                                                borderRadius: 0,
                                                fontSize: 12,
                                                justifyContent: "left"
                                            }}>
                                            {page}
                                        </Button>
                                    </MenuItem>
                                ))}
                        </Menu>
                    </Box>
                    <Box sx={{
                            flexGrow: 1,
                            display: { xs: 'none', sm: 'flex' },
                            ".MuiButtonBase-root": {
                                color: "white",
                                background: "transparent",
                                borderRadius: 0,
                                fontSize: 11
                            },
                            ".MuiButtonBase-root:hover": {
                                color: "primary.main",
                                background: "transparent",
                                borderBottom: 1
                            },
                            ".Mui-disabled": {
                                color: "primary.main",
                                background: "transparent",
                                borderBottom: 1
                            }
                        }}>
                        {pages.map(page => (
                            <Button
                                key={page}
                                value={page}
                                variant="text"
                                onClick={this.handleNavButtonClick}>
                                {page}
                            </Button>
                        ))}
                    </Box>
                    <Box sx={{
                            flexGrow: 0,
                            ".MuiButtonBase-root": {
                                color: "white",
                                background: "transparent",
                                borderRadius: 0,
                                fontSize: 11
                            },
                            ".MuiButtonBase-root:hover": {
                                color: "primary.main",
                                background: "transparent",
                                borderBottom: 1
                            },
                        }}>
                        <Button onClick={this.handleDiscord}>Discord</Button>
                    </Box>
                </Toolbar>
            </AppBar>
        )
    }
}

export default MenuBar;