import React from 'react';

import CopyCoffee from './1.001.000.png';
import LoadingWheel from './1.002.000.png';
import PercentSlider from './1.003.000.png';
import CopiedToast from './1.004.000.png';
import Navigation from './1.005.000.png';
import Instructions from './1.006.000.png';
import Dividers from './1.006.001.png';
import Desto from './1.007.000.png';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

class ChangeLog extends React.Component {
    render() {
        return (
            <Box sx={{ color: "secondary.main", ".MuiTypography-root": { fontSize: 12 }}}>
                <Typography sx={{ fontWeight: "bold" }}>
                    1.007.000
                </Typography>
                <Typography>
                    <ul>
                        <li>Restructured routes by separating origin and destinations.</li>
                        <li>Added the ability to copy destinations to clipboard.</li>
                    </ul>
                    <Box sx={{ display: "flex", justifyContent: "center" }}><img alt="dividers" src={Desto} height="100px" /></Box>
                </Typography>
                <br />
                <Typography sx={{ fontWeight: "bold" }}>
                    1.006.001
                </Typography>
                <Typography>
                    <ul>
                        <li>Added dividers to route pulldown for readability.</li>
                    </ul>
                    <Box sx={{ display: "flex", justifyContent: "center" }}><img alt="dividers" src={Dividers} height="200px" /></Box>
                </Typography>
                <br />
                <Typography sx={{ fontWeight: "bold" }}>
                    1.006.000
                </Typography>
                <Typography>
                    <ul>
                        <li>Added instructions to navigation bar.</li>
                    </ul>
                    <Box sx={{ display: "flex", justifyContent: "center" }}><img alt="instructions" src={Instructions} height="50px" /></Box>
                </Typography>
                <br />
                <Typography sx={{ fontWeight: "bold" }}>
                    1.005.001
                </Typography>
                <Typography>
                    <ul>
                        <li>Re-styled percent collateral slider and copy tooltips for readability.</li>
                    </ul>
                </Typography>
                <br />
                <Typography sx={{ fontWeight: "bold" }}>
                    1.005.000
                </Typography>
                <Typography>
                    <ul>
                        <li>Added navigation bar as well as sections for FAQ and Change Log.</li>
                        <li>Added tooltips for the copy buttons.</li>
                    </ul>
                    <Box sx={{ display: "flex", justifyContent: "center" }}><img alt="navigation" src={Navigation} height="35px" /></Box>
                </Typography>
                <br />
                <Typography sx={{ fontWeight: "bold" }}>
                    1.004.000
                </Typography>
                <Typography>
                    <ul>
                        <li>Added "Copied" toasts for user feedback.</li>
                        <li>Increased the contrast of the contract summary section.</li>
                    </ul>
                    <Box sx={{ display: "flex", justifyContent: "center" }}><img alt="copiedtoast" src={CopiedToast} height="50px" /></Box>
                </Typography>
                <br />
                <Typography sx={{ fontWeight: "bold" }}>
                    1.003.000
                </Typography>
                <Typography>
                    <ul>
                        <li>Added precent collateral slider functionality</li>
                        <li>Regional formatting for the manual entry fields.</li>
                    </ul>
                    <Box sx={{ display: "flex", justifyContent: "center" }}><img alt="percentslider" src={PercentSlider} height="50px" /></Box>
                </Typography>
                <br />
                <Typography sx={{ fontWeight: "bold" }}>
                    1.002.001
                </Typography>
                <Typography>
                    <ul><li>Reformatted contract summary section to be bold for readability.</li></ul>
                </Typography>
                <br />
                <Typography sx={{ fontWeight: "bold" }}>
                    1.002.000
                </Typography>
                <Typography>
                    <ul><li>Added loading wheel on appraisal button for user feedback.</li></ul>
                    <Box sx={{ display: "flex", justifyContent: "center" }}><img alt="loadingwheel" src={LoadingWheel} height="40px" /></Box>
                </Typography>
                <br />
                <Typography sx={{ fontWeight: "bold" }}>
                    1.001.000
                </Typography>
                <Typography>
                    <ul><li>Added a copy function for "Coffee Republic".</li></ul>
                    <Box sx={{ display: "flex", justifyContent: "center" }}><img alt="copycoffee" src={CopyCoffee} height="50px" /></Box>
                </Typography>
                <br />
                <Typography sx={{ fontWeight: "bold" }}>
                    1.000.001
                </Typography>
                <Typography>
                    <ul><li>Added link to Horde home page.</li></ul>
                </Typography>
            </Box>
        );
    }
}

export default ChangeLog;