import React from 'react';
import waypoints from './Waypoints.json';

import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { Typography } from '@mui/material';
import { Stack } from '@mui/system';

class RouteOption extends React.Component {
    constructor(props) {
        super(props);
        this.state = { origin: 1, destination: 1 }
        this.handleOriginChange = this.handleOriginChange.bind(this);
        this.handleDestinationChange = this.handleDestinationChange.bind(this);
    }
    
    handleOriginChange (e) {
        this.props.onOriginChange(e.target.value);
        this.setState({ origin: e.target.value, destination: 1 })
    }

    handleDestinationChange (e) {
        this.props.onDestinationChange(e.target.value);
        this.setState({ destination: e.target.value })
    }

    // Renders an option in the select pulldown based on whether the route is bi-direction, one way or general (ie intra)
    renderOriginOptions() {
        return waypoints.map(waypoint => {
            if(waypoint.method === "break") {
                return <Divider
                            textAlign="left"
                            variant="middle"
                            sx={{
                                "&::before, &::after": {
                                    borderColor: "secondary.main",
                                    },
                                }}>
                                    <Typography fontSize={12} fontWeight="bold"><li>{waypoint.info}</li></Typography>
                        </ Divider>
            }
            return <MenuItem key={waypoint.key} value={waypoint.key}>{waypoint.origin}</MenuItem>  
        })
    }

    renderDestinationOptions() {
        return waypoints[this.state.origin].destinations.map(desto => {
            if(desto.method === "break") {
                return <Divider
                            textAlign="left"
                            variant="middle"
                            sx={{
                                "&::before, &::after": {
                                    borderColor: "secondary.main",
                                    },
                                }}>
                                    <Typography fontSize={12} fontWeight="bold"><li>{desto.info}</li></Typography>
                        </ Divider>
            }
            return <MenuItem key={desto.key} value={desto.key}>{desto.destination}</MenuItem> 
        })
    }

    renderHelperText() {
        if(waypoints[this.state.origin].destinations[this.state.destination].method === "rate"){
            return <FormHelperText>
                    {waypoints[this.state.origin].destinations[this.state.destination].rate} ISK / m&sup3; + {waypoints[this.state.origin].destinations[this.state.destination].collateral}% of Total Collateral 
                    {(waypoints[this.state.origin].destinations[this.state.destination].minimumReward !== 0) ? '(35 mill minimum)' : ''}
                </FormHelperText>
        }
        if(waypoints[this.state.origin].destinations[this.state.destination].method === "flat"){
            return <FormHelperText>{waypoints[this.state.origin].destinations[this.state.destination].rate.toLocaleString()} ISK flat rate for 115,000m&sup3;</FormHelperText>
        }
    }
    
    render() {
        return (
            <Stack spacing={1} sx={{ mb: 1, display: 'flex' }}>
            <FormControl sx={{ mb:1 }}>
                <InputLabel id="origin-label" size="small">Origin</InputLabel>
                <Select value={this.state.origin} size="small" labelId="origin-label" label="Origin" onChange={this.handleOriginChange}>
                    {this.renderOriginOptions()}
                </Select>
            </FormControl>
            <FormControl sx={{ mb:1 }}>
                <InputLabel id="desto-label" size="small">Destination</InputLabel>
                <Select value={this.state.destination} size="small" labelId="desto-label" label="Destination" onChange={this.handleDestinationChange}>
                    {this.renderDestinationOptions()}
                </Select>
                    {this.renderHelperText()}
            </FormControl>
            </Stack>
        )
    }
}

export default RouteOption;