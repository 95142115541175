import React from 'react';
import './App.css';

import Appraisal from '../Appraisal/Appraisal';
import ChangeLog from '../ChangeLog/ChangeLog';
import ContractSummary from '../ContractSummary/ContractSummary';
import FAQ from '../FAQ/FAQ';
import Footer from '../Footer/Footer';
import Instructions from '../Instructions/Instructions';
import ManualAddition from '../ManualAddition/ManualAddition';
import MenuBar from '../MenuBar/MenuBar';

import appraisal from '../../util/Janice';
import waypoints from '../../components/RouteOption/Waypoints.json';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { DialogContentText } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';

// Modify primary and secondary color
const theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: '#ffa000',
      dark: '#ff8f00',
    },
    secondary: {
      main: '#212121',
    },
  },
});

// Most states are determined here
class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      originOption: 1,
      destinationOption: 1,
      destination: "MJ-5F9 - B E A N S T A R",
      rate: 350,
      precentCharge: 1,
      minReward: 0,
      maxVolume: 350000,
      method: "rate",
      appraisalCollateral: 0,
      apprasialVolume: 0,
      apprasialVolumeOverload: false,
      appraisalCollateralOverload: false,
      appraisalLoading: false,
      appraisalError: "",
      percentCollateral: 0,
      manualCollateral: 0,
      manualVolume: 0,
      totalCollateral: 0,
      totalVolume: 0,
      collateralOverload: false,
      volumeOverload: false,
      reward: 0,
      janiceUrlCode: "",
      dialogWindow: false,
      dialogPage: ""
    }
    this.updateOrigin = this.updateOrigin.bind(this);
    this.updateDestination = this.updateDestination.bind(this);
    this.appraisal = this.appraisal.bind(this);
    this.updateManualCollateral = this.updateManualCollateral.bind(this);
    this.updateManualVolume = this.updateManualVolume.bind(this);
    this.calculateReward = this.calculateReward.bind(this);
    this.clearAppraisal = this.clearAppraisal.bind(this);
    this.updatePercentCollateral = this.updatePercentCollateral.bind(this);
    this.handleDialogWindow = this.handleDialogWindow.bind(this);
    this.handleCloseDialogWindow = this.handleCloseDialogWindow.bind(this);
    this.appraisalVolumeCheck = this.appraisalVolumeCheck.bind(this);
    this.totalVolumeCheck = this.totalVolumeCheck.bind(this);
  }

  // Route index is selected from RouteOptions, this index corrisponds with Routes.json
  updateOrigin(originOption) {
    let originIndex = Number(originOption);
    let destinationIndex = 1;
    let rate = waypoints[originIndex].destinations[destinationIndex].rate;
    let precentCharge = waypoints[originIndex].destinations[destinationIndex].collateral;
    let minReward = waypoints[originIndex].destinations[destinationIndex].minimumReward;
    let maxVolume = waypoints[originIndex].destinations[destinationIndex].maximumVolume;
    let method = waypoints[originIndex].destinations[destinationIndex].method;
    let destination = waypoints[originIndex].destinations[destinationIndex].destinationFull;

    this.setState({
      originOption: originIndex,
      destinationOption: destinationIndex,
      rate: rate,
      precentCharge: precentCharge,
      minReward: minReward,
      maxVolume: maxVolume,
      method: method,
      destination: destination
    });

    this.calculateReward(this.state.totalCollateral, this.state.totalVolume, rate, precentCharge, method, minReward);
    this.appraisalVolumeCheck(this.state.apprasialVolume, maxVolume);

    if (this.state.manualVolume !== 0) {
      this.totalVolumeCheck(this.state.totalVolume, maxVolume);
    }
  }

  updateDestination(destinationOption) {
    let originIndex = this.state.originOption;
    let destinationIndex = Number(destinationOption);
    let rate = waypoints[originIndex].destinations[destinationIndex].rate;
    let precentCharge = waypoints[originIndex].destinations[destinationIndex].collateral;
    let minReward = waypoints[originIndex].destinations[destinationIndex].minimumReward;
    let maxVolume = waypoints[originIndex].destinations[destinationIndex].maximumVolume;
    let method = waypoints[originIndex].destinations[destinationIndex].method;
    let destination = waypoints[originIndex].destinations[destinationIndex].destinationFull;

    this.setState({
      destinationOption: destinationIndex,
      rate: rate,
      precentCharge: precentCharge,
      minReward: minReward,
      maxVolume: maxVolume,
      method: method,
      destination: destination
    });

    this.calculateReward(this.state.totalCollateral, this.state.totalVolume, rate, precentCharge, method, minReward);
    this.appraisalVolumeCheck(this.state.apprasialVolume, maxVolume);
    
    if (this.state.manualVolume !== 0) {
      this.totalVolumeCheck(this.state.totalVolume, maxVolume);
    }
  }

  // Recalculates total volume and collateral once Janice promise returns
  // Determines if capacity errors exist in the appraisal
  // Resets manual collateral, volume and percent collateral on call
  appraisal(payload) {
    this.setState({
      appraisalLoading: true,
      manualCollateral: 0,
      manualVolume: 0,
      percentCollateral: 0,
      collateralOverload: false,
      volumeOverload: false
    })
    appraisal(payload).then(result => {
      let totalCollateral = Math.floor(this.state.manualCollateral + result.collateral);
      let totalVolume = Math.floor(this.state.manualVolume + result.volume);
      let maxVolume = this.state.maxVolume;
      this.setState({
        appraisalCollateral: result.collateral,
        apprasialVolume: result.volume,
        appraisalLoading: false,
        totalCollateral: totalCollateral,
        totalVolume: totalVolume,
        appraisalError: result.appraisalErrors,
        janiceUrlCode: result.janiceUrlCode
      });

      if (result.collateral > 50000000000) {
        this.setState({ appraisalCollateralOverload: true });
      } else {
        this.setState({ appraisalCollateralOverload: false });
      }
      
      if (result.volume > maxVolume) {
        this.setState({ apprasialVolumeOverload: true });
      } else {
        this.setState({ apprasialVolumeOverload: false });
      }

      if (result.collateral <= 50000000000 && result.volume <= maxVolume) {
        this.calculateReward(totalCollateral, totalVolume, this.state.rate, this.state.precentCharge, this.state.method, this.state.minReward);
      }
    })
  }

  // Recalculates collateral as user enters and points to only this input if
  // the collateral exceeds limit rather than the total. The logic is, the user
  // should not tack on too much extra to an existing appraisal
  updateManualCollateral(manualCollateral) {
    let percentCollateral = 0;
    let totalCollateral = Math.floor(this.state.appraisalCollateral + Number(manualCollateral));

    if (this.state.appraisalCollateral !== 0) {
      percentCollateral = Number(manualCollateral) / this.state.appraisalCollateral * 100;
    }

    this.setState({
      manualCollateral: Number(manualCollateral),
      totalCollateral: totalCollateral
    });
    if (totalCollateral > 50000000000) {
      this.setState({
        collateralOverload: true,
        percentCollateral: percentCollateral
      })
    } else {
      this.setState({
        collateralOverload: false,
        percentCollateral: percentCollateral
      });
      this.calculateReward(totalCollateral, this.state.totalVolume, this.state.rate, this.state.precentCharge, this.state.method, this.state.minReward);
    }
  }

  updatePercentCollateral(percentCollateral) {
    let rate = this.state.rate;
    let precentCharge = this.state.precentCharge;
    let manualCollateral = Math.floor(this.state.appraisalCollateral * Number(percentCollateral/100))
    let totalCollateral = Math.floor(this.state.appraisalCollateral + manualCollateral)
    let totalVolume = this.state.totalVolume
    this.setState({
      manualCollateral: manualCollateral,
      percentCollateral: percentCollateral,
      totalCollateral: totalCollateral
    });
    this.calculateReward(totalCollateral, totalVolume, rate, precentCharge, this.state.method, this.state.minReward);
  }

  // Recalculates volume as user enters and points to only this input if
  // the volume exceeds limit rather than the total. The logic is, the user
  // should not tack on too much extra to an existing appraisal
  updateManualVolume(manualVolume) {
    let totalVolume = Math.floor(this.state.apprasialVolume + Number(manualVolume));
    this.setState({
      manualVolume: Number(manualVolume),
      totalVolume: totalVolume
    });

      this.calculateReward(this.state.totalCollateral, totalVolume, this.state.rate, this.state.precentCharge, this.state.method, this.state.minReward);
      this.totalVolumeCheck(totalVolume, this.state.maxVolume);
  }

  appraisalVolumeCheck(appraisalVolume, maxVolume) {
    if (appraisalVolume > maxVolume) {
      this.setState({ apprasialVolumeOverload: true })
    } else {
      this.setState({ apprasialVolumeOverload: false })
    }
  }

  totalVolumeCheck(totalVolume, maxVolume) {
    if(totalVolume > maxVolume) {
      this.setState({ volumeOverload: true })
    } else {
      this.setState({ volumeOverload: false })
    }
  }

  // Calculate function, calculates based on the method from Route.json
  // Currently rate and flat are the two methods
  calculateReward(collateral, volume, rate, precentCharge, method, minimum) {
    let reward = 0;
    if (method === "rate") {
      reward = Math.floor(Math.max((volume * rate) + (collateral * precentCharge / 100), minimum));
      this.setState({ reward: reward });
    }
    if (method === "flat") {
      reward = Math.floor(Math.max(rate, minimum));
      this.setState({ reward: reward });
    }
  }

  // Clears all all states except route, the assumption is the user
  // is entering multiple contracts along the same route. Because of this,
  // the calculateReward function is being called to recalculate the reward
  // instead of resetting the reward to 0 otherwise any routes that are flat
  // rate or have a min will show an improper reward until the user enters an input.
  // Left appraisalLoading in in case Janice API never returns promise
  clearAppraisal() {
    this.setState({
      appraisalCollateral: 0,
      apprasialVolume: 0,
      destination: "MJ-5F9 - B E A N S T A R",
      apprasialVolumeOverload: false,
      appraisalCollateralOverload: false,
      appraisalLoading: false,
      appraisalError: "",
      percentCollateral: 0,
      manualCollateral: 0,
      manualVolume: 0,
      totalCollateral: 0,
      totalVolume: 0,
      collateralOverload: false,
      volumeOverload: false,
      janiceUrlCode: ""
    })
    this.calculateReward(0, 0, 350, 1, "rate", 0);
  }

  // Renders a dialog pop up when a navigation menu item is selected
  handleDialogWindow(pageIndex) {
    this.setState({ dialogWindow: true, dialogPage: pageIndex });
  }

  handleCloseDialogWindow() {
    this.setState({ dialogWindow: false });
  }

  // Renders a specific component in the dialog menu
  renderDialogWindow() {
    if (this.state.dialogPage === "Instructions") {
      return <Instructions />
    } 
    if (this.state.dialogPage === "FAQ") {
      return <FAQ />
    }
    if (this.state.dialogPage === "Change Log") {
      return <ChangeLog />
    }
    return <div>The content of this page is missing!</div>
  }
  
  render() {
    return (
      <div className="App">
        <ThemeProvider theme={theme}>
          <MenuBar dialogWindow={this.handleDialogWindow} />
          <Container maxWidth="md">
            <Grid container spacing={2}>
              <Grid item xs={12} sx={{ minHeight: 150 }} className="Banner" />
              <Grid item xs={12} md={6}>
                <Paper variant="outlined" >
                  <Appraisal
                    onAppraisal={this.appraisal}
                    onOriginChange={this.updateOrigin}
                    onDestinationChange={this.updateDestination}
                    onPercentCollateralChange={this.updatePercentCollateral}
                    onClear={this.clearAppraisal}
                    appraisalLoading={this.state.appraisalLoading}
                    appraisalError={this.state.appraisalError}
                    janiceCode={this.state.janiceUrlCode}
                    appraisalCollateral={this.state.appraisalCollateral}
                    percentCollateral={this.state.percentCollateral}
                    isVolumeOver={this.state.apprasialVolumeOverload}
                    isCollateralOver={this.state.appraisalCollateralOverload}
                    maxVolume={this.state.maxVolume} />
                </Paper>
              </Grid>
              <Grid item xs={12} md={6}>
                <Stack spacing={2}>
                  <Paper variant="outlined">
                    <ManualAddition
                      onManualCollateralChange={this.updateManualCollateral}
                      onManualVolumeChange={this.updateManualVolume}
                      manualCollateral={this.state.manualCollateral}
                      manualVolume={this.state.manualVolume}
                      isCollateralOver={this.state.collateralOverload}
                      isVolumeOver={this.state.volumeOverload}
                      maxVolume={this.state.maxVolume} />
                  </Paper>
                  <Paper variant="outlined" sx={{ bgcolor: "primary.dark"}}>
                    <ContractSummary
                      contractDestination={this.state.destination}
                      totalCollateral={this.state.totalCollateral}
                      totalVolume={this.state.totalVolume}
                      calculatedReward={this.state.reward}
                      isAppraisalCollateralOver={this.state.appraisalCollateralOverload}
                      isAppraisalVolumeOver={this.state.apprasialVolumeOverload}
                      isCollateralOver={this.state.collateralOverload}
                      isVolumeOver={this.state.volumeOverload} />
                  </Paper> 
                </Stack>
              </Grid>
            </Grid>
          </Container>
          <Footer />
          <Dialog
            open={this.state.dialogWindow}
            onClose={this.handleCloseDialogWindow}
            fullWidth
            maxWidth="sm"
            scroll="paper">
              <DialogTitle>{this.state.dialogPage}</DialogTitle>
              <DialogContent dividers>
                <DialogContentText sx={{ fontSize: 14 }} >
                  {this.renderDialogWindow()}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={this.handleCloseDialogWindow} sx={{ color: "secondary.main" }}>Close</Button>
              </DialogActions>
          </Dialog>
        </ThemeProvider>
      </div>
  )}
}

export default App;
