import React from 'react';

import NoCreamorSugar from './NoCreamorSugar.png';

import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

class FAQ extends React.Component {
    handleDiscord() {
        window.open("https://discord.gg/tpQe3yevdz");
    }
    
    render() {
        return (
            <Box sx={{ color: "secondary.main", ".MuiTypography-root": { fontSize: 14 }}}>
                <Typography sx={{ fontWeight: "bold" }}>
                    Do you accept contracts from neutral Jita alts?
                </Typography>
                <Typography>
                    Yes, but you probably will not be able to set a Horde structure as the contract's destination because neutrals cannot see Horde structures.
                    <br /><br />
                    You will need to send an in-game link of the Horde structure to your neutral alt via in-game mail. Using your nuetral, drag that link into the destination field.
                </Typography>
                <br />
                <Typography sx={{ fontWeight: "bold" }}>
                    Do you accept no collateral contracts?
                </Typography>
                <Typography>
                    Not anymore, for numerous reason including things like tracking, please put an estimated value in the collateral field, thank you.
                </Typography>
                <br />
                <Typography sx={{ fontWeight: "bold" }}>
                    Are containers allowed in contracts?
                </Typography>
                <Typography>
                    Yes they are but, I ask that you please mention it in the "Description (optional)" section of your contract otherwise you may experience a delay with your delivery.
                </Typography>
                <br />
                <Typography sx={{ fontWeight: "bold" }}>
                    What is the best way to contact you?
                </Typography>
                <Typography>
                    Join the Dark Horse Discord <Link onClick={this.handleDiscord} underline="hover">https://discord.gg/tpQe3yevdz</Link> and message me there.
                </Typography>
                <br />
                <Typography sx={{ fontWeight: "bold" }}>
                    My route is not in the calculator!?
                </Typography>
                <Typography>
                    If you need a custom route, please contact me on Discord and we can easily work one out.
                </Typography>
                <br />
                <Typography sx={{ fontWeight: "bold" }}>
                    I received a weird message from you?
                </Typography>
                <Typography>
                    I will only contact you through Discord or with the in-game character "No Creamor Sugar". I will never contact you with any of my 1RFC or Horde characters.
                    <br /><br />
                    <Box sx={{ display: "flex", justifyContent: "center" }}><img alt="nocreamorsugar" src={NoCreamorSugar} height="120px" /></Box>
                    <br />
                    One of my competitors has been confirmed to be impersonating me and CCP will take whatever action they feel is appropriate once they complete their investigation. Until then, message me if you are unsure.
                </Typography>
            </Box>
        );
    }
}

export default FAQ;