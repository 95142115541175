import React from 'react';
import AppraisalPercentCollateral from '../AppraisalPrecentCollateral/AppraisalPercentCollateral';

import RouteOption from '../RouteOption/RouteOption';
import AppraisalErrors from '../AppraisalErrors/AppraisalErrors';

import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import ClearIcon from '@mui/icons-material/Clear';
import FormControl from '@mui/material/FormControl';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import LaunchIcon from '@mui/icons-material/Launch';
import LoadingButton from '@mui/lab/LoadingButton';
import RequestQuoteOutlinedIcon from '@mui/icons-material/RequestQuoteOutlined';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

class Appraisal extends React.Component {
    constructor(props) {
        super(props);
        this.state = { payload: '' };
        this.handlePayloadChange = this.handlePayloadChange.bind(this);
        this.handleAppraisal = this.handleAppraisal.bind(this);
        this.handleClearButton = this.handleClearButton.bind(this);
    }

    handlePayloadChange(e) {
        this.setState({ payload: e.target.value });
    }

    handleAppraisal() {
        this.props.onAppraisal(this.state.payload);
    }
    
    // Renders over capacity error text specific to the appraisal payload
    renderHelperText() {
        if (this.props.isVolumeOver && this.props.isCollateralOver) {
            return <div>The volume is over {this.props.maxVolume.toLocaleString()} m&sup3; and the collateral is over 50 billion ISK</div>
        }
        if (this.props.isVolumeOver) {
            return <div>{this.props.maxVolume.toLocaleString()} m&sup3; is the max volume, please split into multiple contracts</div>
        }
        if (this.props.isCollateralOver) {
            return "50 billion ISK is the max collateral, please split into multiple contracts";
        } return " ";
    }

    // Prevents users from sending an appraisal without a payload
    disableAppraisalButtons() {
        if (this.state.payload === '') {
            return true;
        } return false;
    }

    // Renders once an appraisal code has been returned from the Janice API
    disableJaniceButton() {
        if (this.props.janiceCode === '') {
            return true;
        } return false;
    }

    // Constructs and returns the Janice URL
    generateJaniceLink() {
        const baseJanice = "https://janice.e-351.com/a/";
        let janiceCode = this.props.janiceCode;
        return `${baseJanice}${janiceCode}`;
    }

    // Calls the clear appraisal function from App
    handleClearButton() {
        this.props.onClear();
        this.setState({ payload: '' });  
    }

    render() {
        return(
            <FormControl sx={{ m: 2, display: 'flex' }}>
                <RouteOption onOriginChange={this.props.onOriginChange} onDestinationChange={this.props.onDestinationChange} />
                <AppraisalErrors appraisalError={this.props.appraisalError} />
                <TextField
                    id="appraisalField"
                    label="Janice Appraiser"
                    placeholder="Copy in items to appraise"
                    multiline
                    noValidate
                    autoComplete="off"
                    rows={8}
                    value={this.state.payload}
                    inputProps={{style: {fontSize: 12}}}
                    onChange={this.handlePayloadChange}
                    error={this.props.isVolumeOver || this.props.isCollateralOver}
                    helperText={this.renderHelperText()}>
                </TextField>
                <AppraisalPercentCollateral
                    appraisalCollateral={this.props.appraisalCollateral}
                    percentCollateral={this.props.percentCollateral}
                    onPercentCollateralChange={this.props.onPercentCollateralChange} />
                <Stack spacing={1} direction="row" sx={{ mb: 2, display: 'flex' }}>
                    <LoadingButton
                        variant="contained"
                        size="small"
                        sx={{ fontSize: '12px' }}
                        fullWidth
                        onClick={this.handleAppraisal}
                        endIcon={<RequestQuoteOutlinedIcon />}
                        disabled={this.disableAppraisalButtons()}
                        loading={this.props.appraisalLoading}>
                        Appraise
                    </LoadingButton>
                    <Button
                        variant="contained"
                        size="small"
                        sx={{ fontSize: '12px' }}
                        fullWidth
                        href={this.generateJaniceLink()}
                        target="_blank"
                        endIcon={<LaunchIcon />}
                        disabled={this.disableJaniceButton()}>
                        Janice Link
                    </Button>
                    <Button
                        variant="outlined"
                        size="small"
                        sx={{ fontSize: '12px' }}
                        fullWidth
                        onClick={this.handleClearButton}
                        endIcon={<ClearIcon />}
                        disabled={this.disableAppraisalButtons()}>
                        Clear
                    </Button>
                </Stack>
                <Alert
                    severity="warning"
                    icon={<InfoOutlinedIcon sx={{ color: "#0f0f0f" }}/>}
                    sx={{
                        background: "#adadad",
                        fontSize: "12px",
                        color: "#0f0f0f"
                        }}>
                    The following will not appraise properly and should be removed and added manually.
                    <ul>
                        <li>Blueprint Copies</li>
                        <li>Assembled Containers</li>
                        <li>Abyssal Modules</li>
                    </ul>
                </Alert>
            </FormControl>
    )}
}

export default Appraisal;