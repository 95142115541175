import React from 'react';
import './ManualAddition.css'

import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';

class ManualAddition extends React.Component {
    constructor(props) {
        super(props);
        this.handleManualCollateralChange = this.handleManualCollateralChange.bind(this);
        this.handleManualVolumeChange = this.handleManualVolumeChange.bind(this);
    }

    handleManualCollateralChange(e) {
        this.props.onManualCollateralChange(e.target.value);
    }

    handleManualVolumeChange(e) {
        this.props.onManualVolumeChange(e.target.value);
    }

    // Focuses entry when field is selected
    handleFocus(e) {
        e.target.select();
    }

    // Shows warning text when the total calculated collateral is over capacity
    renderCollateralHelperText() {
        if (this.props.isCollateralOver) {
            return "The total collateral is over the 50 billion ISK max";
        } return " ";
    }

    // Formats additional collateral to locale and shorthands to mill / bill
    renderCollateralFormatted() {
        if (this.props.manualCollateral >= 10000000000) {
            return (this.props.manualCollateral / 1000000000).toLocaleString(undefined, {maximumFractionDigits: 1}) + " bill";
        }
        if (this.props.manualCollateral < 10000000000 && this.props.manualCollateral >= 1000000000) {
            return (this.props.manualCollateral / 1000000000).toLocaleString(undefined, {maximumFractionDigits: 2}) + " bill";
        }
        if (this.props.manualCollateral < 1000000000 && this.props.manualCollateral >= 100000000) {
            return (this.props.manualCollateral / 1000000).toLocaleString(undefined, {maximumFractionDigits: 0}) + " mill";
        }
        if (this.props.manualCollateral < 100000000 && this.props.manualCollateral >= 10000000) {
            return (this.props.manualCollateral / 1000000).toLocaleString(undefined, {maximumFractionDigits: 1}) + " mill";
        }
        if (this.props.manualCollateral < 10000000 && this.props.manualCollateral >= 1000000) {
            return (this.props.manualCollateral / 1000000).toLocaleString(undefined, {maximumFractionDigits: 2}) + " mill";
        }
        if (this.props.manualCollateral < 1000000 && this.props.manualCollateral > 0) {
            return this.props.manualCollateral.toLocaleString(undefined, {maximumFractionDigits: 0});
        } return;
    }

    // Shows warning text when the total calculated volume is over capacity
    renderVolmumeHelperText() {
        if (this.props.isVolumeOver) {
            return <div>The total volume is over the {this.props.maxVolume.toLocaleString()} m&sup3; max"</div>
        } return " ";
    }

    // Formats additional volume to locale
    renderVolumeFormatted() {
        if (this.props.manualVolume !== 0) {
            return this.props.manualVolume.toLocaleString(undefined, {maximumFractionDigits: 2});
        } return;
    }

    render() {
        return (   
            <Box sx={{
            ".MuiInputBase-root": {
                fontSize: 14,
            },
            ".MuiInputLabel-root": {
                bgcolor: "white",
                pr: 1,
            },
        }}>
            <FormControl sx={{ m: 2, mb: 0, display: 'flex' }}>
                <TextField
                    id="volumeField"
                    label="Additional Volume"
                    placeholder="Manual Volume Addition"
                    type="number"
                    size="small"
                    InputProps={{ endAdornment: <InputAdornment position="end">{this.renderVolumeFormatted()} m&sup3;</InputAdornment>, }}
                    sx={{ mb:1 }}
                    onChange={this.handleManualVolumeChange}
                    onFocus={this.handleFocus}
                    value={this.props.manualVolume}
                    error={this.props.isVolumeOver}
                    helperText={this.renderVolmumeHelperText()}>
                </TextField>
                <TextField
                    id="collateralField"
                    label="Additional Collateral"
                    placeholder="Manual Collateral Addition"
                    type="number"
                    size="small"
                    InputProps={{ endAdornment: <InputAdornment position="end">{this.renderCollateralFormatted()} ISK</InputAdornment>, }}
                    onChange={this.handleManualCollateralChange}
                    onFocus={this.handleFocus}
                    value={this.props.manualCollateral}
                    error={this.props.isCollateralOver}
                    helperText={this.renderCollateralHelperText()}>
                </TextField>
            </FormControl>
        </Box>
    )}
}

export default ManualAddition;